@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-[#FFFAF5];
  @apply h-full;
}

body {
  @apply h-full;
}

#root {
  @apply h-full;
}

.App {
  @apply relative w-full p-4 mb-6  rounded-lg bg-white  ;
}

[type='checkbox']:checked:hover {
  @apply bg-[#3d7b95];
}
